import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// import p1 from 'assests/Photos/classassembly.jpg';
// import p2 from 'assests/Photos/EventsandActivities/Association.jpg';
// import p3 from 'assests/Photos/extraciricular.jpg';
// import p4 from 'assests/Photos/knowmore/lifeskill/l11.jpg';
// import p5 from 'assests/Photos/schoolprogram.jpg';
// import p6 from 'assests/Photos/knowmore/lifeskill/l2.jpg';

const Team = () => {

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json') // This resolves to /config.json in the static folder
      .then((response) => response.json())
      .then((data) => setConfig(data))
      .catch((error) => console.error('Error fetching config:', error));
  }, []);

  const p1 = `${config.base_image_url}/home/home-static/static-images/classassembly.webp`;
  const p2 = `${config.base_image_url}/home/home-static/static-images/Association.jpg`;
  const p3 = `${config.base_image_url}/home/home-static/static-images/extraciricular.webp`;
  const p4 = `${config.base_image_url}/home/home-static/static-images/sewa.jpg`;
  const p5 = `${config.base_image_url}/home/home-static/static-images/schoolprogram.webp`;
  const p6 = `${config.base_image_url}/home/home-static/static-images/lifeskill.webp`;
  const p7 = `${config.base_image_url}/home/home-static/static-images/CLUB.jpg`;


  const mock = [
    {
      media: p3,
      title: 'Co-Scholastic Activities',
      subtitle:
        '',
      href: '/class-6-field-trip-2024'
    },
    // {
    //   media: p6,
    //   title: 'Student Corner',
    //   subtitle:
    //     '',
    //     href:'/student-cornor',
    // },
    {
      media: p6,
      title: 'Life Skill',
      subtitle:
        '',
      href: '/aluminitalk-2024',
    },
    // {
    //   media: p2,
    //   title: 'CCA/Association Activities',
    //   subtitle:
    //     '',
    //   href:'/clubs',
    // },
    {
      media: p7,
      title: 'Clubs',
      subtitle:
        '',
      href: '/clubss',
    },
    {
      media: p2,
      title: 'Association',
      subtitle:
        '',
      href: '/association',
    },
    {
      media: p5,
      title: 'School Programs',
      subtitle:
        '',
      href: '/orientation-programme-2024'
    },
    {
      media: p1,
      title: 'Class Assembly',
      subtitle:
        '',
      href: '/class-assembly-12a-2024'
    },
    {
      media: p4,
      title: 'SEWA',
      subtitle:
        '',
      href: '/sewa'
    }
  ];


  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const sliderOpts = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: isMd ? 4 : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  return (
    <Box>
      <Typography fontWeight={700} variant={'h4'} align={'center'} id='activities'>
        Our Events and Activities
      </Typography>
      <Box
        data-aos={'fade-up'}
        maxWidth={{ xs: 420, sm: 620, md: 1 }}
        margin={'0 auto'}
      >
        <Slider {...sliderOpts}>
          {mock.map((item, i) => (
            <Box key={i} padding={{ xs: 1, md: 2, lg: 3 }}>
              <Box
                display={'block'}
                width={1}
                height={1}
                boxShadow={0}
                variant={'outlined'}
                bgcolor={'alternate.main'}
                sx={{
                  textDecoration: '',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                  },
                }}
              >
                
                  <Box
                    component={Card}
                    width={1}
                    height={1}
                    display={'flex'}
                    flexDirection={'column'}
                    sx={{ backgroundImage: 'none' }}
                  >
                    <a href={item.href} style={{ textDecoration: 'none', color: 'black' }}>
                    <CardMedia

                      image={item.media}

                      sx={{
                        position: 'relative',
                        height: { xs: 240, sm: 340, md: 280 },
                        overflow: 'hidden',
                      }}

                    >

                    </CardMedia>
                    
                    <CardContent>

                      <Typography
                        variant={'h6'}
                        gutterBottom
                        align={'left'}
                        sx={{ fontWeight: 700 }}>
                        {item.subtitle}
                        {item.title}
                      </Typography>
                    </CardContent>
                    </a>
                    <Box flexGrow={1} />
                    {/* <CardActions sx={{ justifyContent: 'flex-end' }} >
                  
                  <Button
                    size={'large'}
                    sx={{ marginTop: 2 }}
                    href={item.href}
                    endIcon={
                      <Box
                        component={'svg'}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        width={24}
                        height={24}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </Box>
                    }
                  >                   
                    Know more
                  </Button>
                  </CardActions> */}
                  </Box>
              </Box>
            
            </Box>
            
          ))}
    </Slider>
      </Box >
    </Box >
  );
};

export default Team;


